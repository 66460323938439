import shield from '../assets/images/shield.svg';
import { Transaction } from '../services/types';
import styled from 'styled-components';
import copyImage from '../assets/images/copy.svg';
import success from '../assets/images/borderless-success.svg';
import { Dispatch, MouseEvent, SetStateAction, useRef, useState } from 'react';

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: var(--color-blue);
  color: var(--color-white);
  padding: 16px;
`;

const HeaderGroup = styled.div`
  width: 270px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
`;

const Timer = styled.p`
  font-size: 20px;
  color: var(--color-white);
  font-weight: 500;
`;

const Row = styled.div`
  position: relative;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  width: 270px;
  margin-bottom: 6px;
`;

const Content = styled.div`
  display: flex;
`;

const Blob = styled.div`
  position: absolute;
  right: 20px;
  top: 0;
  width: 30px;
  height: 20px;
  background: linear-gradient(
    90deg,
    rgba(86, 123, 255, 0) 0%,
    rgba(86, 123, 255, 1) 100%
  );
  z-index: 2;
`;

const P = styled.p`
  font-size: 12px;
  font-weight: 300;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
`;

const Copy = styled.div`
  background-color: white;
  padding: 4px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  img {
    color: var(--color-blue);
  }
`;

const Header = ({
  transaction,
  ticketId,
  timeLeft,
}: {
  transaction: Transaction;
  ticketId: string;
  timeLeft: string;
}) => {
  const [copiedTransaction, setCopiedTransaction] = useState(false);
  const [copiedUserId, setCopiedUserId] = useState(false);
  const transactionRef = useRef<HTMLSpanElement | null>(null);
  const userIdRef = useRef<HTMLSpanElement | null>(null);
  const { isRedirectUrlExist } = transaction;

  const handleCopy = (
    e: MouseEvent<HTMLParagraphElement>,
    callback: Dispatch<SetStateAction<boolean>>,
    type: 'transaction' | 'user'
  ) => {
    console.log(type);
    navigator.clipboard
      .writeText(
        (type === 'transaction'
          ? transactionRef?.current?.innerText
          : userIdRef?.current?.innerText) || ''
      )
      .then(() => {
        callback(true);

        setTimeout(() => {
          callback(false);
        }, 3000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <HeaderWrapper>
      <HeaderGroup>
        <Title>
          <img src={shield} alt='shield' />
          <h3>Пополнение</h3>
        </Title>
        {!isRedirectUrlExist && <Timer>{timeLeft}</Timer>}
      </HeaderGroup>
      <Row>
        <p>ID транзакции:</p>
        <Blob />
        <Content>
          <P>
            <span ref={transactionRef}>{ticketId}</span>
          </P>
          <Copy>
            {!copiedTransaction && (
              <img
                style={{ objectFit: 'cover' }}
                src={copyImage}
                alt='copy'
                onClick={(e) =>
                  handleCopy(e, setCopiedTransaction, 'transaction')
                }
                width={12}
                height={12}
              />
            )}
            {copiedTransaction && (
              <img src={success} alt='success' width={12} height={12} />
            )}
          </Copy>
        </Content>
      </Row>
      {transaction?.externalUserId && (
        <Row>
          <p>ID пользователя:</p>
          <Blob />
          <Content>
            <P>
              <span ref={userIdRef}>{transaction?.externalUserId}</span>
            </P>
            <Copy>
              {!copiedUserId && (
                <img
                  style={{ objectFit: 'cover' }}
                  src={copyImage}
                  alt='copy'
                  onClick={(e) => handleCopy(e, setCopiedUserId, 'user')}
                  width={12}
                  height={12}
                />
              )}
              {copiedUserId && (
                <img src={success} alt='success' width={12} height={12} />
              )}
            </Copy>
          </Content>
        </Row>
      )}
    </HeaderWrapper>
  );
};

export default Header;
