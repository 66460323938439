import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import errorImage from '../assets/images/error-ok.svg';
import Button from '../ui/widgets/Button/Button';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessageText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: var(--color-blue);
`;

const Container = styled.div`
  width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  background-color: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: var(--border-radius);
  padding: 16px;
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

interface Props {
  onOk?: () => void;
  onRetry?: () => void;
  transaction?: any;
  onCancel?: () => void;
  setRetry?: Dispatch<SetStateAction<number>>;
  retry?: number;
}

const Error: React.FC<Props> = ({
  onOk,
  onRetry,
  setRetry,
  onCancel,
  retry,
  transaction,
}) => {
  const retryHandler = () => {
    if (typeof setRetry === 'function') {
      setRetry((prev) => prev + 1);
    }
  };
  return (
    <Wrapper>
      <Container>
        <img src={errorImage} alt='server error' />
        {onRetry && (
          <MessageText>Транзакция недоступна, попробуйте еще раз</MessageText>
        )}
        {onOk && <MessageText>Ошибка в загрузке страницы</MessageText>}
        {onCancel && <MessageText>Не удалось найти реквизит</MessageText>}
        {setRetry ? (
          <>
            <ButtonGroup>
              <Button style={{ width: '270px' }} onClick={retryHandler}>
                Повторить попытку
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button
                variant='secondary'
                style={{ width: '270px' }}
                onClick={() => {
                  if (transaction) {
                    window.location.replace(transaction.redirectUrl);
                  }
                }}
              >
                Отмена
              </Button>
            </ButtonGroup>
          </>
        ) : (
          <ButtonGroup>
            <Button style={{ width: '270px' }} onClick={onOk}>
              Назад
            </Button>
          </ButtonGroup>
        )}
      </Container>
    </Wrapper>
  );
};

export default Error;
